import React, { Fragment } from 'react';
import { Loader, useAPI, Form, Card, Animate, Grid, Button, } from 'components/lib';

export function AddConnection(props) {


    const languages = useAPI('/api/languages');
    const projects = useAPI('/api/projects');
    const account = useAPI('/api/account');

    if (!languages || !account || !projects)
        return false;

    let fieldToShow = 0;

    if (account?.data?.plan == 'free' && account?.data?.id !== '8fb8f2fb-de0b-46b0-baed-51f746185344') {
        fieldToShow = 2;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMTcxQhYy2w') {
        fieldToShow = 5;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMTc5xucr5B') {
        fieldToShow = 10;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMT6TjQCNds' || account?.data?.id == '8fb8f2fb-de0b-46b0-baed-51f746185344') {
        fieldToShow = 25;
    }


    const labelOne = languages?.data?.langOne + '-' + languages?.data?.countryOne + ' Connection';
    const labelTwo = languages?.data?.langTwo + '-' + languages?.data?.countryTwo + ' Connection';
    const labelThree = languages?.data?.langThree + '-' + languages?.data?.countryThree + ' Connection';
    const labelFour = languages?.data?.langFour + '-' + languages?.data?.countryFour + ' Connection';
    const labelFive = languages?.data?.langFive + '-' + languages?.data?.countryFive + ' Connection';
    const labelSix = languages?.data?.langSix + '-' + languages?.data?.countrySix + ' Connection';
    const labelSeven = languages?.data?.langSeven + '-' + languages?.data?.countrySeven + ' Connection';
    const labelEight = languages?.data?.langEight + '-' + languages?.data?.countryEight + ' Connection';
    const labelNine = languages?.data?.langNine + '-' + languages?.data?.countryNine + ' Connection';
    const labelTen = languages?.data?.langTen + '-' + languages?.data?.countryTen + ' Connection';
    const label11 = languages?.data?.lang11 + '-' + languages?.data?.country11 + ' Connection';
    const label12 = languages?.data?.lang12 + '-' + languages?.data?.country12 + ' Connection';
    const label13 = languages?.data?.lang13 + '-' + languages?.data?.country13 + ' Connection';
    const label14 = languages?.data?.lang14 + '-' + languages?.data?.country14 + ' Connection';
    const label15 = languages?.data?.lang15 + '-' + languages?.data?.country15 + ' Connection';
    const label16 = languages?.data?.lang16 + '-' + languages?.data?.country16 + ' Connection';
    const label17 = languages?.data?.lang17 + '-' + languages?.data?.country17 + ' Connection';
    const label18 = languages?.data?.lang18 + '-' + languages?.data?.country18 + ' Connection';
    const label19 = languages?.data?.lang19 + '-' + languages?.data?.country19 + ' Connection';
    const label20 = languages?.data?.lang20 + '-' + languages?.data?.country20 + ' Connection';
    const label21 = languages?.data?.lang21 + '-' + languages?.data?.country21 + ' Connection';
    const label22 = languages?.data?.lang22 + '-' + languages?.data?.country22 + ' Connection';
    const label23 = languages?.data?.lang23 + '-' + languages?.data?.country23 + ' Connection';
    const label24 = languages?.data?.lang24 + '-' + languages?.data?.country24 + ' Connection';
    const label25 = languages?.data?.lang25 + '-' + languages?.data?.country25 + ' Connection';


    function upload(data) {
        window.location = '/upload/'
    }

    return (
        <Fragment>
            <Animate>

                <Grid cols='4'>
                    <Button
                        text='Bulk Upload Connections'
                        action={upload}
                    />
                </Grid>
                {languages?.loading ?
                    <Loader /> :
                    <Grid cols="1">
                        {account.loading ?
                            <Loader /> :
                            <Card title="Add a Connection">

                                <Form
                                    buttonText='Add Connection'
                                    url='/api/connections'
                                    method='POST'
                                    inputs={{
                                        project_id: {
                                            label: 'Project',
                                            type: 'select',
                                            options: projects.data && projects.data.map(x => {
                                                return {

                                                    value: x.id, label: x.name

                                                }
                                            })
                                        },
                                        ...(labelOne !== 'null-null Connection' && {
                                            linkOne: {
                                                label: labelOne,
                                                type: 'text',
                                            },
                                        }),
                                        ...(labelTwo !== 'null-null Connection' && {
                                            linkTwo: {
                                                label: labelTwo,
                                                type: 'text',
                                            },
                                        }),
                                        ...(fieldToShow > 2
                                            ? {
                                                ...(labelThree !== 'null-null Connection' && {
                                                    linkThree: {
                                                        label: labelThree,
                                                        type: 'text',
                                                    },
                                                }),

                                                ...(labelFour !== 'null-null Connection' && {
                                                    linkFour: {
                                                        label: labelFour,
                                                        type: 'text',
                                                    },
                                                }),
                                                ...(labelFive !== 'null-null Connection' && {
                                                    linkFive: {
                                                        label: labelFive,
                                                        type: 'text',
                                                    },
                                                }),
                                                ...(fieldToShow > 5
                                                    ? {
                                                        ...(labelSix !== 'null-null Connection' && {
                                                            linkSix: {
                                                                label: labelSix,
                                                                type: 'text',
                                                            },
                                                        }),
                                                        ...(labelSeven !== 'null-null Connection' && {
                                                            linkSeven: {
                                                                label: labelSeven,
                                                                type: 'text',
                                                            },
                                                        }),
                                                        ...(labelEight !== 'null-null Connection' && {
                                                            linkEight: {
                                                                label: labelEight,
                                                                type: 'text',
                                                            },
                                                        }),
                                                        ...(labelNine !== 'null-null Connection' && {
                                                            linkNine: {
                                                                label: labelNine,
                                                                type: 'text',
                                                            },
                                                        }),
                                                        ...(labelTen !== 'null-null Connection' && {
                                                            linkTen: {
                                                                label: labelTen,
                                                                type: 'text',
                                                            },
                                                        }),
                                                        ...(fieldToShow > 10
                                                            ? {
                                                                ...(label11 !== 'null-null Connection' && {
                                                                    link11: {
                                                                        label: label11,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label12 !== 'null-null Connection' && {
                                                                    link12: {
                                                                        label: label12,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label13 !== 'null-null Connection' && {
                                                                    link13: {
                                                                        label: label13,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label14 !== 'null-null Connection' && {
                                                                    link14: {
                                                                        label: label14,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label15 !== 'null-null Connection' && {
                                                                    link15: {
                                                                        label: label15,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label16 !== 'null-null Connection' && {
                                                                    link11: {
                                                                        label: label16,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label17 !== 'null-null Connection' && {
                                                                    link17: {
                                                                        label: label17,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label18 !== 'null-null Connection' && {
                                                                    link18: {
                                                                        label: label18,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label19 !== 'null-null Connection' && {
                                                                    link19: {
                                                                        label: label19,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label20 !== 'null-null Connection' && {
                                                                    link20: {
                                                                        label: label20,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label21 !== 'null-null Connection' && {
                                                                    link21: {
                                                                        label: label21,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label22 !== 'null-null Connection' && {
                                                                    link22: {
                                                                        label: label22,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label23 !== 'null-null Connection' && {
                                                                    link23: {
                                                                        label: label23,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label24 !== 'null-null Connection' && {
                                                                    link24: {
                                                                        label: label24,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                                ...(label25 !== 'null-null Connection' && {
                                                                    link25: {
                                                                        label: label25,
                                                                        type: 'text',
                                                                    },
                                                                }),
                                                            }
                                                            : {}
                                                        ),

                                                    }
                                                    : {}
                                                ),
                                            }
                                            : {}
                                        ),
                                    }}
                                />

                            </Card>
                        }

                    </Grid>
                }
            </Animate>
        </Fragment>
    );
}