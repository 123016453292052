import { Fragment, useState } from 'react';
import { Loader, useAPI, Form, Card, Animate, Grid, Message } from 'components/lib';

export function Languages(props) {

    const [state, setState] = useState({});
    const data = useAPI('/api/languages');
    const account = useAPI('/api/account');

    if (!data || !account)
        return false;

    let fieldToShow = 0;

    if (account?.data?.plan == 'free' && account?.data?.id !== '8fb8f2fb-de0b-46b0-baed-51f746185344') {
        fieldToShow = 2;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMTcxQhYy2w') {
        fieldToShow = 5;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMTc5xucr5B') {
        fieldToShow = 10;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMT6TjQCNds' || account?.data?.id == '8fb8f2fb-de0b-46b0-baed-51f746185344') {
        fieldToShow = 25;
    }

    let updateURL = '/api/languages/' + data?.data?.id;

    const languages = [
        { value: 'aa', label: 'Afar' },
        { value: 'ab', label: 'Abkhazian' },
        { value: 'af', label: 'Afrikaans' },
        { value: 'ak', label: 'Akan' },
        { value: 'am', label: 'Amharic' },
        { value: 'ar', label: 'Arabic' },
        { value: 'as', label: 'Assamese' },
        { value: 'ay', label: 'Aymara' },
        { value: 'az', label: 'Azerbaijani' },
        { value: 'ba', label: 'Bashkir' },
        { value: 'be', label: 'Belarusian' },
        { value: 'bg', label: 'Bulgarian' },
        { value: 'bh', label: 'Bihari' },
        { value: 'bi', label: 'Bislama' },
        { value: 'bn', label: 'Bengali' },
        { value: 'bo', label: 'Tibetan' },
        { value: 'br', label: 'Breton' },
        { value: 'bs', label: 'Bosnian' },
        { value: 'ca', label: 'Catalan' },
        { value: 'ce', label: 'Chechen' },
        { value: 'ch', label: 'Chamorro' },
        { value: 'co', label: 'Corsican' },
        { value: 'cr', label: 'Cree' },
        { value: 'cs', label: 'Czech' },
        { value: 'cu', label: 'Church Slavic' },
        { value: 'cv', label: 'Chuvash' },
        { value: 'cy', label: 'Welsh' },
        { value: 'da', label: 'Danish' },
        { value: 'de', label: 'German' },
        { value: 'dv', label: 'Divehi' },
        { value: 'dz', label: 'Dzongkha' },
        { value: 'ee', label: 'Ewe' },
        { value: 'el', label: 'Greek' },
        { value: 'en', label: 'English' },
        { value: 'eo', label: 'Esperanto' },
        { value: 'es', label: 'Spanish' },
        { value: 'et', label: 'Estonian' },
        { value: 'eu', label: 'Basque' },
        { value: 'fa', label: 'Persian' },
        { value: 'ff', label: 'Fulah' },
        { value: 'fi', label: 'Finnish' },
        { value: 'fj', label: 'Fijian' },
        { value: 'fo', label: 'Faroese' },
        { value: 'fr', label: 'French' },
        { value: 'fy', label: 'Western Frisian' },
        { value: 'ga', label: 'Irish' },
        { value: 'gd', label: 'Scottish Gaelic' },
        { value: 'gl', label: 'Galician' },
        { value: 'gn', label: 'Guarani' },
        { value: 'gu', label: 'Gujarati' },
        { value: 'gv', label: 'Manx' },
        { value: 'ha', label: 'Hausa' },
        { value: 'he', label: 'Hebrew' },
        { value: 'hi', label: 'Hindi' },
        { value: 'ho', label: 'Hiri Motu' },
        { value: 'hr', label: 'Croatian' },
        { value: 'ht', label: 'Haitian' },
        { value: 'hu', label: 'Hungarian' },
        { value: 'hy', label: 'Armenian' },
        { value: 'hz', label: 'Herero' },
        { value: 'ia', label: 'Interlingua' },
        { value: 'id', label: 'Indonesian' },
        { value: 'ie', label: 'Interlingue' },
        { value: 'ig', label: 'Igbo' },
        { value: 'ii', label: 'Sichuan Yi' },
        { value: 'ik', label: 'Inupiaq' },
        { value: 'io', label: 'Ido' },
        { value: 'is', label: 'Icelandic' },
        { value: 'it', label: 'Italian' },
        { value: 'iu', label: 'Inuktitut' },
        { value: 'ja', label: 'Japanese' },
        { value: 'jv', label: 'Javanese' },
        { value: 'ka', label: 'Georgian' },
        { value: 'kg', label: 'Kongo' },
        { value: 'ki', label: 'Kikuyu' },
        { value: 'kj', label: 'Kwanyama' },
        { value: 'kk', label: 'Kazakh' },
        { value: 'kl', label: 'Kalaallisut' },
        { value: 'km', label: 'Khmer' },
        { value: 'kn', label: 'Kannada' },
        { value: 'ko', label: 'Korean' },
        { value: 'kr', label: 'Kanuri' },
        { value: 'ks', label: 'Kashmiri' },
        { value: 'ku', label: 'Kurdish' },
        { value: 'kv', label: 'Komi' },
        { value: 'kw', label: 'Cornish' },
        { value: 'ky', label: 'Kirghiz' },
        { value: 'la', label: 'Latin' },
        { value: 'lb', label: 'Luxembourgish' },
        { value: 'lg', label: 'Ganda' },
        { value: 'li', label: 'Limburgish' },
        { value: 'ln', label: 'Lingala' },
        { value: 'lo', label: 'Lao' },
        { value: 'lt', label: 'Lithuanian' },
        { value: 'lu', label: 'Luba-Katanga' },
        { value: 'lv', label: 'Latvian' },
        { value: 'mg', label: 'Malagasy' },
        { value: 'mh', label: 'Marshallese' },
        { value: 'mi', label: 'Maori' },
        { value: 'mk', label: 'Macedonian' },
        { value: 'ml', label: 'Malayalam' },
        { value: 'mn', label: 'Mongolian' },
        { value: 'mr', label: 'Marathi' },
        { value: 'ms', label: 'Malay' },
        { value: 'mt', label: 'Maltese' },
        { value: 'my', label: 'Burmese' },
        { value: 'na', label: 'Nauru' },
        { value: 'nb', label: 'Norwegian Bokmål' },
        { value: 'nd', label: 'North Ndebele' },
        { value: 'ne', label: 'Nepali' },
        { value: 'ng', label: 'Ndonga' },
        { value: 'nl', label: 'Dutch' },
        { value: 'nn', label: 'Norwegian Nynorsk' },
        { value: 'no', label: 'Norwegian' },
        { value: 'nr', label: 'South Ndebele' },
        { value: 'nv', label: 'Navajo' },
        { value: 'ny', label: 'Chichewa' },
        { value: 'oc', label: 'Occitan' },
        { value: 'oj', label: 'Ojibwa' },
        { value: 'om', label: 'Oromo' },
        { value: 'or', label: 'Oriya' },
        { value: 'os', label: 'Ossetian' },
        { value: 'pa', label: 'Punjabi' },
        { value: 'pi', label: 'Pali' },
        { value: 'pl', label: 'Polish' },
        { value: 'ps', label: 'Pashto' },
        { value: 'pt', label: 'Portuguese' },
        { value: 'qu', label: 'Quechua' },
        { value: 'rm', label: 'Romansh' },
        { value: 'rn', label: 'Rundi' },
        { value: 'ro', label: 'Romanian' },
        { value: 'ru', label: 'Russian' },
        { value: 'rw', label: 'Kinyarwanda' },
        { value: 'sa', label: 'Sanskrit' },
        { value: 'sc', label: 'Sardinian' },
        { value: 'sd', label: 'Sindhi' },
        { value: 'se', label: 'Northern Sami' },
        { value: 'sg', label: 'Sango' },
        { value: 'si', label: 'Sinhala' },
        { value: 'sk', label: 'Slovak' },
        { value: 'sl', label: 'Slovenian' },
        { value: 'sm', label: 'Samoan' },
        { value: 'sn', label: 'Shona' },
        { value: 'so', label: 'Somali' },
        { value: 'sq', label: 'Albanian' },
        { value: 'sr', label: 'Serbian' },
        { value: 'ss', label: 'Swati' },
        { value: 'st', label: 'Sotho, Southern' },
        { value: 'su', label: 'Sundanese' },
        { value: 'sv', label: 'Swedish' },
        { value: 'sw', label: 'Swahili' },
        { value: 'ta', label: 'Tamil' },
        { value: 'te', label: 'Telugu' },
        { value: 'tg', label: 'Tajik' },
        { value: 'th', label: 'Thai' },
        { value: 'ti', label: 'Tigrinya' },
        { value: 'tk', label: 'Turkmen' },
        { value: 'tl', label: 'Tagalog' },
        { value: 'tn', label: 'Tswana' },
        { value: 'to', label: 'Tonga (Tonga Islands)' },
        { value: 'tr', label: 'Turkish' },
        { value: 'ts', label: 'Tsonga' },
        { value: 'tt', label: 'Tatar' },
        { value: 'tw', label: 'Twi' },
        { value: 'ty', label: 'Tahitian' },
        { value: 'ug', label: 'Uighur' },
        { value: 'uk', label: 'Ukrainian' },
        { value: 'ur', label: 'Urdu' },
        { value: 'uz', label: 'Uzbek' },
        { value: 've', label: 'Venda' },
        { value: 'vi', label: 'Vietnamese' },
        { value: 'vo', label: 'Volapük' },
        { value: 'wa', label: 'Walloon' },
        { value: 'wo', label: 'Wolof' },
        { value: 'xh', label: 'Xhosa' },
        { value: 'yi', label: 'Yiddish' },
        { value: 'yo', label: 'Yoruba' },
        { value: 'za', label: 'Zhuang' },
        { value: 'zh', label: 'Chinese' },
        { value: 'zu', label: 'Zulu' }];

    const countries = [
        { value: 'af', label: 'Afghanistan' },
        { value: 'al', label: 'Albania' },
        { value: 'dz', label: 'Algeria' },
        { value: 'ad', label: 'Andorra' },
        { value: 'ao', label: 'Angola' },
        { value: 'ag', label: 'Antigua and Barbuda' },
        { value: 'ar', label: 'Argentina' },
        { value: 'am', label: 'Armenia' },
        { value: 'au', label: 'Australia' },
        { value: 'at', label: 'Austria' },
        { value: 'az', label: 'Azerbaijan' },
        { value: 'bs', label: 'Bahamas' },
        { value: 'bh', label: 'Bahrain' },
        { value: 'bd', label: 'Bangladesh' },
        { value: 'bb', label: 'Barbados' },
        { value: 'by', label: 'Belarus' },
        { value: 'be', label: 'Belgium' },
        { value: 'bz', label: 'Belize' },
        { value: 'bj', label: 'Benin' },
        { value: 'bt', label: 'Bhutan' },
        { value: 'bo', label: 'Bolivia' },
        { value: 'ba', label: 'Bosnia and Herzegovina' },
        { value: 'bw', label: 'Botswana' },
        { value: 'br', label: 'Brazil' },
        { value: 'bn', label: 'Brunei' },
        { value: 'bg', label: 'Bulgaria' },
        { value: 'bf', label: 'Burkina Faso' },
        { value: 'bi', label: 'Burundi' },
        { value: 'cv', label: 'Cabo Verde' },
        { value: 'kh', label: 'Cambodia' },
        { value: 'cm', label: 'Cameroon' },
        { value: 'ca', label: 'Canada' },
        { value: 'cf', label: 'Central African Republic' },
        { value: 'td', label: 'Chad' },
        { value: 'cl', label: 'Chile' },
        { value: 'cn', label: 'China' },
        { value: 'co', label: 'Colombia' },
        { value: 'km', label: 'Comoros' },
        { value: 'cd', label: 'Congo (Congo-Brazzaville)' },
        { value: 'cg', label: 'Congo, Democratic Republic of the' },
        { value: 'cr', label: 'Costa Rica' },
        { value: 'ci', label: 'Côte d\'Ivoire' },
        { value: 'hr', label: 'Croatia' },
        { value: 'cu', label: 'Cuba' },
        { value: 'cy', label: 'Cyprus' },
        { value: 'cz', label: 'Czechia (Czech Republic)' },
        { value: 'dk', label: 'Denmark' },
        { value: 'dj', label: 'Djibouti' },
        { value: 'dm', label: 'Dominica' },
        { value: 'do', label: 'Dominican Republic' },
        { value: 'ec', label: 'Ecuador' },
        { value: 'eg', label: 'Egypt' },
        { value: 'sv', label: 'El Salvador' },
        { value: 'gq', label: 'Equatorial Guinea' },
        { value: 'er', label: 'Eritrea' },
        { value: 'ee', label: 'Estonia' },
        { value: 'sz', label: 'Eswatini' },
        { value: 'et', label: 'Ethiopia' },
        { value: 'fj', label: 'Fiji' },
        { value: 'fi', label: 'Finland' },
        { value: 'fr', label: 'France' },
        { value: 'ga', label: 'Gabon' },
        { value: 'gm', label: 'Gambia' },
        { value: 'ge', label: 'Georgia' },
        { value: 'de', label: 'Germany' },
        { value: 'gh', label: 'Ghana' },
        { value: 'gr', label: 'Greece' },
        { value: 'gd', label: 'Grenada' },
        { value: 'gt', label: 'Guatemala' },
        { value: 'gn', label: 'Guinea' },
        { value: 'gw', label: 'Guinea-Bissau' },
        { value: 'gy', label: 'Guyana' },
        { value: 'ht', label: 'Haiti' },
        { value: 'hn', label: 'Honduras' },
        { value: 'hu', label: 'Hungary' },
        { value: 'is', label: 'Iceland' },
        { value: 'in', label: 'India' },
        { value: 'id', label: 'Indonesia' },
        { value: 'ir', label: 'Iran' },
        { value: 'iq', label: 'Iraq' },
        { value: 'ie', label: 'Ireland' },
        { value: 'il', label: 'Israel' },
        { value: 'it', label: 'Italy' },
        { value: 'jm', label: 'Jamaica' },
        { value: 'jp', label: 'Japan' },
        { value: 'jo', label: 'Jordan' },
        { value: 'kz', label: 'Kazakhstan' },
        { value: 'ke', label: 'Kenya' },
        { value: 'ki', label: 'Kiribati' },
        { value: 'kp', label: 'Korea, North' },
        { value: 'kr', label: 'Korea, South' },
        { value: 'kw', label: 'Kuwait' },
        { value: 'kg', label: 'Kyrgyzstan' },
        { value: 'la', label: 'Laos' },
        { value: 'lv', label: 'Latvia' },
        { value: 'lb', label: 'Lebanon' },
        { value: 'ls', label: 'Lesotho' },
        { value: 'lr', label: 'Liberia' },
        { value: 'ly', label: 'Libya' },
        { value: 'li', label: 'Liechtenstein' },
        { value: 'lt', label: 'Lithuania' },
        { value: 'lu', label: 'Luxembourg' },
        { value: 'mk', label: 'North Macedonia' },
        { value: 'mg', label: 'Madagascar' },
        { value: 'mw', label: 'Malawi' },
        { value: 'my', label: 'Malaysia' },
        { value: 'mv', label: 'Maldives' },
        { value: 'ml', label: 'Mali' },
        { value: 'mt', label: 'Malta' },
        { value: 'mh', label: 'Marshall Islands' },
        { value: 'mr', label: 'Mauritania' },
        { value: 'mu', label: 'Mauritius' },
        { value: 'mx', label: 'Mexico' },
        { value: 'fm', label: 'Micronesia' },
        { value: 'md', label: 'Moldova' },
        { value: 'mc', label: 'Monaco' },
        { value: 'mn', label: 'Mongolia' },
        { value: 'me', label: 'Montenegro' },
        { value: 'ma', label: 'Morocco' },
        { value: 'mz', label: 'Mozambique' },
        { value: 'mm', label: 'Myanmar (formerly Burma)' },
        { value: 'na', label: 'Namibia' },
        { value: 'nr', label: 'Nauru' },
        { value: 'np', label: 'Nepal' },
        { value: 'nl', label: 'Netherlands' },
        { value: 'nz', label: 'New Zealand' },
        { value: 'ni', label: 'Nicaragua' },
        { value: 'ne', label: 'Niger' },
        { value: 'ng', label: 'Nigeria' },
        { value: 'no', label: 'Norway' },
        { value: 'om', label: 'Oman' },
        { value: 'pk', label: 'Pakistan' },
        { value: 'pw', label: 'Palau' },
        { value: 'pa', label: 'Panama' },
        { value: 'pg', label: 'Papua New Guinea' },
        { value: 'py', label: 'Paraguay' },
        { value: 'pe', label: 'Peru' },
        { value: 'ph', label: 'Philippines' },
        { value: 'pl', label: 'Poland' },
        { value: 'pt', label: 'Portugal' },
        { value: 'qa', label: 'Qatar' },
        { value: 'ro', label: 'Romania' },
        { value: 'ru', label: 'Russia' },
        { value: 'rw', label: 'Rwanda' },
        { value: 'kn', label: 'Saint Kitts and Nevis' },
        { value: 'lc', label: 'Saint Lucia' },
        { value: 'vc', label: 'Saint Vincent and the Grenadines' },
        { value: 'ws', label: 'Samoa' },
        { value: 'sm', label: 'San Marino' },
        { value: 'st', label: 'Sao Tome and Principe' },
        { value: 'sa', label: 'Saudi Arabia' },
        { value: 'sn', label: 'Senegal' },
        { value: 'rs', label: 'Serbia' },
        { value: 'sc', label: 'Seychelles' },
        { value: 'sl', label: 'Sierra Leone' },
        { value: 'sg', label: 'Singapore' },
        { value: 'sk', label: 'Slovakia' },
        { value: 'si', label: 'Slovenia' },
        { value: 'sb', label: 'Solomon Islands' },
        { value: 'so', label: 'Somalia' },
        { value: 'za', label: 'South Africa' },
        { value: 'ss', label: 'South Sudan' },
        { value: 'es', label: 'Spain' },
        { value: 'lk', label: 'Sri Lanka' },
        { value: 'sd', label: 'Sudan' },
        { value: 'sr', label: 'Suriname' },
        { value: 'se', label: 'Sweden' },
        { value: 'ch', label: 'Switzerland' },
        { value: 'sy', label: 'Syria' },
        { value: 'tw', label: 'Taiwan' },
        { value: 'tj', label: 'Tajikistan' },
        { value: 'tz', label: 'Tanzania' },
        { value: 'th', label: 'Thailand' },
        { value: 'tl', label: 'Timor-Leste' },
        { value: 'tg', label: 'Togo' },
        { value: 'to', label: 'Tonga' },
        { value: 'tt', label: 'Trinidad and Tobago' },
        { value: 'tn', label: 'Tunisia' },
        { value: 'tr', label: 'Turkey' },
        { value: 'tm', label: 'Turkmenistan' },
        { value: 'tv', label: 'Tuvalu' },
        { value: 'ug', label: 'Uganda' },
        { value: 'ua', label: 'Ukraine' },
        { value: 'ae', label: 'United Arab Emirates' },
        { value: 'gb', label: 'United Kingdom' },
        { value: 'us', label: 'United States of America' },
        { value: 'uy', label: 'Uruguay' },
        { value: 'uz', label: 'Uzbekistan' },
        { value: 'vu', label: 'Vanuatu' },
        { value: 'va', label: 'Vatican City' },
        { value: 've', label: 'Venezuela' },
        { value: 'vn', label: 'Vietnam' },
        { value: 'ye', label: 'Yemen' },
        { value: 'zm', label: 'Zambia' },
        { value: 'zw', label: 'Zimbabwe' }
    ];

    let langOne = data?.data?.langOne;
    let countryOne = data?.data?.countryOne;

    let langTwo = data?.data?.langTwo;
    let countryTwo = data?.data?.countryTwo;

    let langThree = data?.data?.langThree;
    let countryThree = data?.data?.countryThree;

    let langFour = data?.data?.langFour;
    let countryFour = data?.data?.countryFour;

    let langFive = data?.data?.langFive;
    let countryFive = data?.data?.countryFive;

    let langSix = data?.data?.langSix;
    let countrySix = data?.data?.countrySix;

    let langSeven = data?.data?.langSeven;
    let countrySeven = data?.data?.countrySeven;

    let langEight = data?.data?.langEight;
    let countryEight = data?.data?.countryEight;

    let langNine = data?.data?.langNine;
    let countryNine = data?.data?.countryNine;

    let langTen = data?.data?.langTen;
    let countryTen = data?.data?.countryTen;

    let lang11 = data?.data?.lang11;
    let country11 = data?.data?.country11;

    let lang12 = data?.data?.lang12;
    let country12 = data?.data?.country12;

    let lang13 = data?.data?.lang13;
    let country13 = data?.data?.country13;

    let lang14 = data?.data?.lang14;
    let country14 = data?.data?.country14;

    let lang15 = data?.data?.lang15;
    let country15 = data?.data?.country15;

    let lang16 = data?.data?.lang16;
    let country16 = data?.data?.country16;

    let lang17 = data?.data?.lang17;
    let country17 = data?.data?.country17;

    let lang18 = data?.data?.lang18;
    let country18 = data?.data?.country18;

    let lang19 = data?.data?.lang19;
    let country19 = data?.data?.country19;

    let lang20 = data?.data?.lang20;
    let country20 = data?.data?.country20;

    let lang21 = data?.data?.lang21;
    let country21 = data?.data?.country21;

    let lang22 = data?.data?.lang22;
    let country22 = data?.data?.country22;

    let lang23 = data?.data?.lang23;
    let country23 = data?.data?.country23;

    let lang24 = data?.data?.lang24;
    let country24 = data?.data?.country24;

    let lang25 = data?.data?.lang25;
    let country25 = data?.data?.country25;


    return (
        <Animate type='pop'>
            <Message
                closable
                title='Updating Languages and Locales'
                text='Below are the languages included in your plan. These define all how your HREFlang tags will be setup. Add the Language and Country you are aiming at with that page.'
                type='info'
            />
            {data?.data?.loading ?
                <Loader /> :
                <Grid cols="10">
                    {account.loading ?
                        <Loader /> :
                        <Card restrictWidth>
                            <Form
                                buttonText='Update'
                                url={updateURL}
                                method='PATCH'
                                inputs={{
                                    langOne: {
                                        label: 'Language 1',
                                        type: 'select',
                                        default: langOne,
                                        options: languages,

                                    },
                                    countryOne: {
                                        label: 'Country 1',
                                        type: 'select',
                                        default: countryOne,
                                        options: countries,

                                    },

                                    langTwo: {
                                        label: 'Language 2',
                                        type: 'select',
                                        default: langTwo,
                                        options: languages,

                                    },
                                    countryTwo: {
                                        label: 'Country 2',
                                        type: 'select',
                                        default: countryTwo,
                                        options: countries,

                                    },
                                    ...(fieldToShow > 2
                                        ? {
                                            langThree: {
                                                label: 'Language 3',
                                                type: 'select',
                                                default: langThree,
                                                options: languages,
                                            },
                                            countryThree: {
                                                label: 'Country 3',
                                                type: 'select',
                                                default: countryThree,
                                                options: countries,
                                            },
                                            langFour: {
                                                label: 'Language 4',
                                                type: 'select',
                                                default: langFour,
                                                options: languages,

                                            },
                                            countryFour: {
                                                label: 'Country 4',
                                                type: 'select',
                                                default: countryFour,
                                                options: countries,

                                            },

                                            langFive: {
                                                label: 'Language 5',
                                                type: 'select',
                                                default: langFive,
                                                options: languages,

                                            },
                                            countryFive: {
                                                label: 'Country 5',
                                                type: 'select',
                                                default: countryFive,
                                                options: countries,

                                            },
                                            ...(fieldToShow > 5
                                                ? {
                                                    langSix: {
                                                        label: 'Language 6',
                                                        type: 'select',
                                                        default: langSix,
                                                        options: languages,

                                                    },
                                                    countrySix: {
                                                        label: 'Country 6',
                                                        type: 'select',
                                                        default: countrySix,
                                                        options: countries,

                                                    },

                                                    langSeven: {
                                                        label: 'Language 7',
                                                        type: 'select',
                                                        default: langSeven,
                                                        options: languages,

                                                    },
                                                    countrySeven: {
                                                        label: 'Country 7',
                                                        type: 'select',
                                                        default: countrySeven,
                                                        options: countries,

                                                    },

                                                    langEight: {
                                                        label: 'Language 8',
                                                        type: 'select',
                                                        default: langEight,
                                                        options: languages,

                                                    },
                                                    countryEight: {
                                                        label: 'Country 8',
                                                        type: 'select',
                                                        default: countryEight,
                                                        options: countries,

                                                    },

                                                    langNine: {
                                                        label: 'Language 9',
                                                        type: 'select',
                                                        default: langNine,
                                                        options: languages,

                                                    },
                                                    countryNine: {
                                                        label: 'Country 9',
                                                        type: 'select',
                                                        default: countryNine,
                                                        options: countries,

                                                    },

                                                    langTen: {
                                                        label: 'Language 10',
                                                        type: 'select',
                                                        default: langTen,
                                                        options: languages,

                                                    },
                                                    countryTen: {
                                                        label: 'Country 10',
                                                        type: 'select',
                                                        default: countryTen,
                                                        options: countries,

                                                    },
                                                    ...(fieldToShow > 10
                                                        ? {
                                                            lang11: {
                                                                label: 'Language 11',
                                                                type: 'select',
                                                                default: lang11,
                                                                options: languages,
                                                            },
                                                            country11: {
                                                                label: 'Country 11',
                                                                type: 'select',
                                                                default: country11,
                                                                options: countries,
                                                            },
                                                            lang12: {
                                                                label: 'Language 12',
                                                                type: 'select',
                                                                default: lang12,
                                                                options: languages,
                                                            },
                                                            country12: {
                                                                label: 'Country 12',
                                                                type: 'select',
                                                                default: country12,
                                                                options: countries,
                                                            },
                                                            lang13: {
                                                                label: 'Language 13',
                                                                type: 'select',
                                                                default: lang13,
                                                                options: languages,
                                                            },
                                                            country13: {
                                                                label: 'Country 13',
                                                                type: 'select',
                                                                default: country13,
                                                                options: countries,
                                                            },
                                                            lang14: {
                                                                label: 'Language 14',
                                                                type: 'select',
                                                                default: lang14,
                                                                options: languages,
                                                            },
                                                            country14: {
                                                                label: 'Country 14',
                                                                type: 'select',
                                                                default: country14,
                                                                options: countries,
                                                            },
                                                            lang15: {
                                                                label: 'Language 15',
                                                                type: 'select',
                                                                default: lang15,
                                                                options: languages,
                                                            },
                                                            country15: {
                                                                label: 'Country 15',
                                                                type: 'select',
                                                                default: country15,
                                                                options: countries,
                                                            },
                                                            lang16: {
                                                                label: 'Language 16',
                                                                type: 'select',
                                                                default: lang16,
                                                                options: languages,
                                                            },
                                                            country16: {
                                                                label: 'Country 16',
                                                                type: 'select',
                                                                default: country16,
                                                                options: countries,
                                                            },
                                                            lang17: {
                                                                label: 'Language 17',
                                                                type: 'select',
                                                                default: lang17,
                                                                options: languages,
                                                            },
                                                            country17: {
                                                                label: 'Country 17',
                                                                type: 'select',
                                                                default: country17,
                                                                options: countries,
                                                            },
                                                            lang18: {
                                                                label: 'Language 18',
                                                                type: 'select',
                                                                default: lang18,
                                                                options: languages,
                                                            },
                                                            country18: {
                                                                label: 'Country 18',
                                                                type: 'select',
                                                                default: country18,
                                                                options: countries,
                                                            },
                                                            lang19: {
                                                                label: 'Language 19',
                                                                type: 'select',
                                                                default: lang19,
                                                                options: languages,
                                                            },
                                                            country19: {
                                                                label: 'Country 19',
                                                                type: 'select',
                                                                default: country19,
                                                                options: countries,
                                                            },
                                                            lang20: {
                                                                label: 'Language 20',
                                                                type: 'select',
                                                                default: lang20,
                                                                options: languages,
                                                            },
                                                            country20: {
                                                                label: 'Country 20',
                                                                type: 'select',
                                                                default: country20,
                                                                options: countries,
                                                            },
                                                            lang21: {
                                                                label: 'Language 21',
                                                                type: 'select',
                                                                default: lang21,
                                                                options: languages,
                                                            },
                                                            country21: {
                                                                label: 'Country 21',
                                                                type: 'select',
                                                                default: country21,
                                                                options: countries,
                                                            },
                                                            lang22: {
                                                                label: 'Language 22',
                                                                type: 'select',
                                                                default: lang22,
                                                                options: languages,
                                                            },
                                                            country22: {
                                                                label: 'Country 22',
                                                                type: 'select',
                                                                default: country22,
                                                                options: countries,
                                                            },
                                                            lang23: {
                                                                label: 'Language 23',
                                                                type: 'select',
                                                                default: lang23,
                                                                options: languages,
                                                            },
                                                            country23: {
                                                                label: 'Country 23',
                                                                type: 'select',
                                                                default: country23,
                                                                options: countries,
                                                            },
                                                            lang24: {
                                                                label: 'Language 24',
                                                                type: 'select',
                                                                default: lang24,
                                                                options: languages,
                                                            },
                                                            country24: {
                                                                label: 'Country 24',
                                                                type: 'select',
                                                                default: country24,
                                                                options: countries,
                                                            },
                                                            lang25: {
                                                                label: 'Language 25',
                                                                type: 'select',
                                                                default: lang25,
                                                                options: languages,
                                                            },
                                                            country25: {
                                                                label: 'Country 25',
                                                                type: 'select',
                                                                default: country25,
                                                                options: countries,
                                                            },

                                                        }
                                                        : {}
                                                    ),
                                                }
                                                : {}
                                            ),
                                        }
                                        : {}
                                    ),
                                }}
                            />
                        </Card>
                    }
                </Grid>
            }

        </Animate >

    );
}
