import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Loader, useAPI, Form, Card, Animate, Grid, Table, Button, ViewContext, usePermissions } from 'components/lib';

export function Connections(props) {
    const languages = useAPI('/api/languages');
    const projects = useAPI('/api/projects');
    const context = useContext(ViewContext);
    const connections = useAPI('/api/connections');

    if (!languages || !projects || !connections)
        return false;

    function deleteConnection(data, callback) {
        context.modal.show({
            title: 'Delete Connection',
            form: {},
            buttonText: 'Delete',
            text: `Are you sure you want to delete ${data.linkOne}?`,
            url: `/api/connections/${data.id}`,
            method: 'DELETE',
            destructive: true
        }, () => {
            context.notification.show(data.linkOne + ' was deleted', 'success', true);
            callback();
        });
    }

    function viewConnection(data) {
        window.location = '/connection/' + data.id;
    }

    function addConnection(data) {
        window.location = '/addConnection/';
    }

    function viewConnections(data) {
        window.location = '/connections/';
    }

    function setLanguages(data) {
        window.location = '/languages/';
    }

    function projectsMain(data) {
        window.location = '/projects/';
    }

    function projectLink(data) {
        window.location = '/project/'+data;
    }

    return (
        <Fragment>
            <Animate>
                {projects.loading ? (
                    <Loader />
                ) : (
                    <Grid cols="1">
                        <div>
                            <Card title="Your Projects" last>
                                <Grid cols="6">
                                    {projects.data && projects.data.map((x) => (
                                       <Button text={x.name} action={() => projectLink(x.id)} /> // Wrap the function call in an arrow function
                                    ))}
                                </Grid>
                            </Card>
                            <Card title='Current Connections' last>
                                <Table
                                    search
                                    data={connections.data}
                                    show={['linkOne', 'langOne', 'countryOne']}
                                    loading={connections.loading}
                                    actions={{ delete: deleteConnection, custom: [{ icon: 'eye', action: viewConnection }] }}
                                >
                                </Table>
                            </Card>
                            <Card title='Quick Links'>
                                <Grid cols='4'>
                                    <Button
                                        text='Add a Connection'
                                        action={addConnection}
                                    />
                                    <Button
                                        text='View Connections'
                                        action={viewConnections}
                                    />
                                    <Button
                                        text='Set Languages'
                                        action={setLanguages}
                                    />
                                    <Button
                                        text='Projects'
                                        action={projectsMain}
                                    />
                                </Grid>
                            </Card>
                        </div>
                    </Grid>
                )}
            </Animate>
        </Fragment>
    );
}
