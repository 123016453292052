import { Fragment, useState } from 'react';
import { Loader, useAPI, Table, Form, Card, Animate } from 'components/lib';

export function Projects(props) {

    const projects = useAPI('/api/projects');

    return (
        <Animate type='pop'>
            <Card title='Current Projects' last>
                <Table
                    search
                    data={projects.data}
                    show={['name', 'url']}
                    loading={projects.loading}
                >
                </Table>
            </Card>
            <Card title='Add a New Project' restrictWidth>
                <Form
                    buttonText='Add Project'
                    url='/api/projects'
                    method='POST'
                    inputs={{
                        name: {
                            label: 'Project Name',
                            type: 'text',
                        },
                        url: {
                            label: 'Project URL',
                            type: 'text',
                        },
                    }}
                />
            </Card>
        </Animate >

    );
}
