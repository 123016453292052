/***
*
*   FORGOT PASSWORD
*   Trigger a password reset process
*
**********/

import React from 'react';
import { Animate, Row, Form, Card } from 'components/lib';

export function ForgotPassword(props) {

    return (
        <Animate type='pop'>
            <section class="py-24 lg:pb-32 bg-gray-100 overflow-hidden">
                <div class="container px-4 mx-auto">
                    <div class="max-w-lg text-center mx-auto">
                        <h2 class="font-heading mb-12 text-6xl tracking-tighter">Reset Your Password</h2>
                        <div class="text-left mb-6 p-9 bg-white border border-gray-200 rounded-2xl">
                            <Card restrictWidth center>

                                <p className='mb-5'>Enter your email address and we'll send you
                                    instructions to reset your password.</p>

                                <Form
                                    inputs={{
                                        email: {
                                            label: 'Email',
                                            type: 'email',
                                            required: true
                                        }
                                    }}
                                    url='/api/auth/password/reset/request'
                                    method='POST'
                                    buttonText='Reset Password'
                                />

                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </Animate>
    );
}
