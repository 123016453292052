/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React from 'react';
import {
  Card, Stat, ProgressBar, Chart, Table, Button,
  Message, Grid, Animate, Feedback, useAPI
} from 'components/lib';

export function Dashboard(props) {

  const stats = useAPI('/api/connectionsStats');
  
  const connections = useAPI('/api/connectionsLatest');
  
  function addConnection(data) {
    window.location = '/addConnection/'
  }
  function viewConnections(data) {
    window.location = '/connections/'
  }
  function setLanguages(data) {
    window.location = '/languages/'
  }
  function projects(data) {
    window.location = '/projects/'
  }

  return (
    <Animate type='pop'>

      <Message
        closable
        title='Welcome to HrefLang Manager!'
        text='Use the menu on the left to add languages, locales and connections to load on your multilingual websites.'
        type='info'
      />

      <Grid cols='3'>
        <Stat
          loading={stats?.loading}
          value={stats?.data?.connections}
          label='connections'
          icon='link-2'
        />
        <Stat
          loading={stats?.loading}
          value={stats?.data?.languages}
          label='languages'
          icon='speaker'
        />
        <Stat
          loading={stats?.loading}
          value={stats?.data?.locales}
          label='locales'
          icon='globe'
        />
      </Grid>

      <Card title='Quick Links'>
        <Grid cols='4'>
          <Button
            text='Add a Connection'
            action={addConnection}
          />
          <Button
            text='View Connections'
            action={viewConnections}
          />
          <Button
            text='Set Languages'
            action={setLanguages}
          />
          <Button
            text='Projects'
            action={projects}
          />
        </Grid>
      </Card>

      <Card title='Latest Connections' last>
        <Table
          data={connections.data}
          show={['linkOne']}
          loading={connections.loading}
        >
        </Table>
      </Card>

      <Feedback />

    </Animate>
  );
}
