import { Implement } from 'views/implement';
import { AddConnection } from 'views/addConnection';
import { Uploaded } from 'views/uploaded';
import { Upload } from 'views/upload';
import { Connection } from 'views/connection';
import { Languages } from 'views/languages';
import { Projects } from 'views/projects';
import { Project } from 'views/project';
import { Connections } from 'views/connections';
import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/connections',
    view: Connections,
    layout: 'app',
    permission: 'user',
    title: 'Connections'
  },
  {
    path: '/languages',
    view: Languages,
    layout: 'app',
    permission: 'user',
    title: 'Your Languages and Locales'
  },
  {
    path: '/connection',
    view: Connection,
    layout: 'app',
    permission: 'user',
    title: 'Connection'
  },
  {
    path: '/connection/:id',
    view: Connection,
    layout: 'app',
    permission: 'user',
    title: 'Connection'
  },
  {
    path: '/upload',
    view: Upload,
    layout: 'app',
    permission: 'user',
    title: 'Upload'
  },
  {
    path: '/uploaded',
    view: Uploaded,
    layout: 'app',
    permission: 'user',
    title: 'Uploaded'
  },
  {
    path: '/addConnection',
    view: AddConnection,
    layout: 'app',
    permission: 'user',
    title: 'Add Connection'
  },
  {
    path: '/projects',
    view: Projects,
    layout: 'app',
    permission: 'user',
    title: 'Projects'
  },
  {
    path: '/project/:id',
    view: Project,
    layout: 'app',
    permission: 'user',
    title: 'Project'
  },
  {
    path: '/implement',
    view: Implement,
    layout: 'app',
    permission: 'user',
    title: 'Implement'
  },
]

export default Routes;
