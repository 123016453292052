const Style = {

  base: 'inline-block mx-1 py-1 px-2.5 text-white uppercase text-xs rounded',
  red: 'bg-red-500',
  blue: 'bg-indigo-500',
  green: 'bg-indigo-500',
  orange: 'bg-indigo-500',

}

export default Style;