/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Message, Card, useAPI } from 'components/lib';

export function OnboardingView(props) {

    const context = useContext(AuthContext);
    const views = [{

        name: 'Getting Started',
        description: `Welcome, ${context.user.name}!`,
        component: <Welcome />

    }]

    if (context.permission.admin) {
        views.push({

            name: 'Invite your team',
            description: 'Hreflang Manager is more fun when you invite your team.',
            component: <InviteUsers />,

        });
    }

    if (context.user.duplicate_user) {
        views.unshift({

            name: 'Important!',
            description: '',
            component: <DuplicateUser />

        })
    }

    return <Onboarding save onFinish='/dashboard' views={views} />

}

function DuplicateUser() {

    return (
        <Message
            type='warning'
            title={`You already have an account`}
            text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
        />
    )
}

function Welcome() {

    return (
        
        <Fragment>

            <p>Thanks for signing up to Hreflang Manager.</p>

            <p>First off, we need to know which languages and locations you are aiming for. This can be done when on your dashboard, the menu on the left has a Languages link where they can be defined.</p>

            <p>After they have been defined, you can add connections. This can be done manually, or with a bulk CSV upload. Please refer to the documentation to see how to do this.</p>

            <p>For now, let's add any more users you need to the account...</p>

        </Fragment>
    )
}

function InviteUsers(props) {

    return (
        <Form
            inputs={{
                email: {
                    label: 'Email',
                    type: 'email',
                    required: true,
                }
            }}
            buttonText='Send Invite'
            url='/api/invite'
            method='POST'
        />
    )
}