/***
*
*   RESET PASSWORD
*   User can set a new password using the token
*
**********/

import React, { useContext } from 'react';
import { Animate, Row, AuthContext, Card, Form, useNavigate, useLocation } from 'components/lib';

export function ResetPassword(props) {

    // context
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(AuthContext)

    // check for token
    const qs = location.search;
    const token = qs.substring(qs.indexOf('?token=') + 7);

    return (
        <Animate type='pop'>
            <section class="py-24 lg:pb-32 bg-gray-100 overflow-hidden">
                <div class="container px-4 mx-auto">
                    <div class="max-w-lg text-center mx-auto">
                        <h2 class="font-heading mb-12 text-6xl tracking-tighter">Reset Your Password</h2>
                        <div class="text-left mb-6 p-9 bg-white border border-gray-200 rounded-2xl">
                            <Card restrictWidth center>
                                <Form
                                    inputs={{
                                        jwt: {
                                            type: 'hidden',
                                            value: token,
                                        },
                                        email: {
                                            label: 'Email',
                                            type: 'email',
                                            required: true
                                        },
                                        password: {
                                            label: 'New Password',
                                            type: 'password',
                                            required: true,
                                            complexPassword: true,
                                        }
                                    }}
                                    url='/api/auth/password/reset'
                                    method='POST'
                                    buttonText='Set New Password'
                                    callback={res => {

                                        res.data['2fa_required'] ? navigate(`/signin/otp?token=${res.data.token}`) : context.signin(res);

                                    }}
                                />
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </Animate>
    );
}
