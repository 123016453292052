import React, { useState, Fragment } from 'react';
import { Button, Message, Card, useAPI, Form } from 'components/lib';

export function Upload(props) {
    const [state, setState] = useState({});
    const [uploaded, setUploaded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const projects = useAPI('/api/projects');
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const contents = e.target.result;
            const rows = contents.split('\n');
            const headers = [
                'linkOne',
                'linkTwo',
                'linkThree',
                'linkFour',
                'linkFive',
                'linkSix',
                'linkSeven',
                'linkEight',
                'linkNine',
                'linkTen',
                'link11',
                'link12',
                'link13',
                'link14',
                'link15',
                'link16',
                'link17',
                'link18',
                'link19',
                'link20',
                'link21',
                'link22',
                'link23',
                'link24',
                'link25',
            ];

            const data = rows
                .slice(1)
                .map((row) => row.split(','))
                .map((values) => {
                    return headers.reduce((obj, header, index) => {
                        const trimmedValue = values[index] ? values[index].trim() : null; // Set empty values as null
                        obj[header] = trimmedValue !== '' ? trimmedValue : null;
                        return obj;
                    }, {});
                });

            const filteredData = data.filter((row, index) => {
                for (let i = 0; i < headers.length; i++) {
                    const currentValue = row[headers[i]];
                    if (currentValue !== null && currentValue.trim() !== '') {
                        for (let j = 0; j < index; j++) {
                            const previousValue = data[j][headers[i]];
                            if (
                                previousValue !== null &&
                                previousValue.trim() !== '' &&
                                previousValue === currentValue
                            ) {
                                return false;
                            }
                        }
                    }
                }
                return true;
            });

            const jsonData = JSON.stringify(filteredData);
            setState({ jsonData, filteredData });
            setUploaded(true);
        };

        reader.readAsText(file);
    };

    return (
        <Fragment>
            <Message
                closable
                title="Bulk Add Connections"
                text="Add a CSV file below, then press the Upload button to add these connections to your project. A preview is given after your file has uploaded."
                type="info"
            />
            <Card title="Upload a CSV">
                <input className="mb-4" type="file" onChange={handleFileUpload} />
                {uploaded && !processing && (
                    <Form
                        buttonText="Upload"
                        url="/api/connectionsBulk"
                        method="POST"
                        inputs={{
                            uploadedContent: {
                                label: 'Upload Content',
                                type: 'hidden',
                                value: state.jsonData,
                            },
                            project_id: {
                                label: 'Project',
                                type: 'select',
                                options: projects.data && projects.data.map(x => {
                                    return {

                                        value: x.id, label: x.name

                                    }
                                })
                            },
                        }}
                    />
                )}
            </Card>
            {processing && <p>Processing...</p>}
            {state.filteredData && state.filteredData.length > 0 && (
                <Card title="Your Upload">
                    <div className="w-full mt-6 pb-6 overflow-x-auto">
                        <table className="w-full min-w-max">
                            <thead>
                                <tr className="text-left">
                                    {Object.keys(state.filteredData[0]).map((header, index) => (
                                        <th
                                            className="px-3 py-3.5 text-base font-semibold"
                                            key={index}
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {state.filteredData.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((value, index) => (
                                            <td
                                                className="whitespace-nowrap px-3 py-4 text-sm border-b"
                                                key={index}
                                            >
                                                {value}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}
        </Fragment>
    );
}
