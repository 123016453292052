/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, Logo, Link, Button, Content, ClassHelper } from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props){

  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });

  return(
    
    <div class="bg-white">
    <div class="container px-4 mx-auto">
        <div class="flex items-center justify-between py-5">
            <div class="w-auto">
                <div class="flex flex-wrap items-center">
                    <div class="w-auto pr-2">
                        <a class="block" href="https://hreflangmanager.com">
                            <img class="inline-block w-10 h-10" src="https://hreflangmanager.com/img/hreflang-manager-logo.png" alt="Hreflang Manager Logo"></img>
                            <span class="inline-block pt-2">Hreflang Manager</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="w-auto">
                <div class="flex flex-wrap items-center">
                    <div class="w-auto hidden lg:block">
                        <ul class="flex items-center mr-8">
                            <li class="mr-14 font-medium hover:text-gray-900 tracking-tight"><a href="https://hreflangmanager.com/features/">Features</a></li>
                            <li class="mr-14 font-medium hover:text-gray-900 tracking-tight"><a href="https://hreflangmanager.com/pricing/">Pricing</a></li>
                            <li class="mr-8 font-medium hover:text-gray-900 tracking-tight border-r pr-8"><a href="https://hreflangmanager.com/docs/">Documentation</a></li>
                            <li class="font-medium hover:text-gray-900 tracking-tight"><a href="/signin">Login</a></li>
                        </ul>
                    </div>
                    <div class="w-auto hidden lg:block">
                        <div class="inline-block"><a class="inline-block px-5 py-3 text-white font-semibold text-center tracking-tight bg-indigo-500 hover:bg-indigo-600 rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200" href="/signup">Try Our Free Plan</a></div>
                    </div>
                    <div class="w-auto lg:hidden">
                        <a href="#">
                            <svg class="navbar-burger text-indigo-600" width="51" height="51" viewbox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="28" fill="currentColor"></rect>
                                <path d="M37 32H19M37 24H19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
        <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
        <nav class="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto">
            <div class="flex flex-wrap justify-between h-full">
                <div class="w-full">
                    <div class="flex items-center justify-between -m-2">
                        <div class="w-auto p-2">
                            <a class="inline-block" href="https://hreflangmanager.com">
                                <img src="https://hreflangmanager.com/img/hreflang-manager-logo.png" alt="Hreflang Manager Logo"></img>
                            </a>
                        </div>
                        <div class="w-auto p-2">
                            <a class="navbar-burger" href="#">
                                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6L18 18" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col justify-center py-16 w-full">
                    <ul>
                        <li class="mb-12 font-medium hover:text-gray-900 tracking-tight"><a href="#">Features</a></li>
                        <li class="mb-12 font-medium hover:text-gray-900 tracking-tight"><a href="#">Pricing</a></li>
                        <li class="mb-12 font-medium hover:text-gray-900 tracking-tight"><a href="#">Automation</a></li>
                        <li class="font-medium hover:text-gray-900 tracking-tight"><a href="#">Customer Login</a></li>
                    </ul>
                </div>
                <div class="flex flex-col justify-end w-full pb-8"><a class="inline-block px-5 py-3 text-white font-semibold text-center tracking-tight bg-indigo-500 hover:bg-indigo-600 rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200" href="/signup">Try 14 Days Free Trial</a></div>
            </div>
        </nav>
    </div>
</div>
  );
}