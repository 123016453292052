import { Fragment, useState } from 'react';
import { Loader, useAPI, Animate, Grid, Card, Form, Message } from 'components/lib';
import { useParams } from "react-router";
export function Connection(props) {

    const params = useParams();
    const connection = useAPI('/api/connections/single/' + params.id);
    const languages = useAPI('/api/languages/');
    const account = useAPI('/api/account');

    if (!languages || !account || !connection)
        return false;

    let fieldToShow = 0;

    if (account?.data?.plan == 'free' && account?.data?.id !== '8fb8f2fb-de0b-46b0-baed-51f746185344') {
        fieldToShow = 2;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMTcxQhYy2w') {
        fieldToShow = 5;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMTc5xucr5B') {
        fieldToShow = 10;
    } else if (account?.data?.plan == 'price_1M00nBGQDK0RzcMT6TjQCNds' || account?.data?.id == '8fb8f2fb-de0b-46b0-baed-51f746185344') {
        fieldToShow = 25;
    }

    const labelOne = languages?.data?.langOne + '-' + languages?.data?.countryOne + ' Connection';
    const labelTwo = languages?.data?.langTwo + '-' + languages?.data?.countryTwo + ' Connection';
    const labelThree = languages?.data?.langThree + '-' + languages?.data?.countryThree + ' Connection';
    const labelFour = languages?.data?.langFour + '-' + languages?.data?.countryFour + ' Connection';
    const labelFive = languages?.data?.langFive + '-' + languages?.data?.countryFive + ' Connection';
    const labelSix = languages?.data?.langSix + '-' + languages?.data?.countrySix + ' Connection';
    const labelSeven = languages?.data?.langSeven + '-' + languages?.data?.countrySeven + ' Connection';
    const labelEight = languages?.data?.langEight + '-' + languages?.data?.countryEight + ' Connection';
    const labelNine = languages?.data?.langNine + '-' + languages?.data?.countryNine + ' Connection';
    const labelTen = languages?.data?.langTen + '-' + languages?.data?.countryTen + ' Connection';
    const label11 = languages?.data?.lang11 + '-' + languages?.data?.country11 + ' Connection';
    const label12 = languages?.data?.lang12 + '-' + languages?.data?.country12 + ' Connection';
    const label13 = languages?.data?.lang13 + '-' + languages?.data?.country13 + ' Connection';
    const label14 = languages?.data?.lang14 + '-' + languages?.data?.country14 + ' Connection';
    const label15 = languages?.data?.lang15 + '-' + languages?.data?.country15 + ' Connection';
    const label16 = languages?.data?.lang16 + '-' + languages?.data?.country16 + ' Connection';
    const label17 = languages?.data?.lang17 + '-' + languages?.data?.country17 + ' Connection';
    const label18 = languages?.data?.lang18 + '-' + languages?.data?.country18 + ' Connection';
    const label19 = languages?.data?.lang19 + '-' + languages?.data?.country19 + ' Connection';
    const label20 = languages?.data?.lang20 + '-' + languages?.data?.country20 + ' Connection';
    const label21 = languages?.data?.lang21 + '-' + languages?.data?.country21 + ' Connection';
    const label22 = languages?.data?.lang22 + '-' + languages?.data?.country22 + ' Connection';
    const label23 = languages?.data?.lang23 + '-' + languages?.data?.country23 + ' Connection';
    const label24 = languages?.data?.lang24 + '-' + languages?.data?.country24 + ' Connection';
    const label25 = languages?.data?.lang25 + '-' + languages?.data?.country25 + ' Connection';

    if (!languages)
        return false;

    let updateURL = '/api/connections/' + params.id;

    return (

        <Animate type='pop'>
            <Message
                closable
                title='Update Connection'
                text=''
                type='info'
            />
            {languages.loading ?
                <Loader /> :
                <div>
                    {connection.loading ?
                        <Loader /> :

                        <Grid cols="2">

                            <Card>
                                <Form
                                    buttonText='Update'
                                    url={updateURL}
                                    method='PATCH'
                                    inputs={{
                                        linkOne: {
                                            label: labelOne,
                                            type: 'text',
                                            value: connection?.data?.linkOne,

                                        },
                                    }}
                                />
                            </Card>
                            <Card>
                                <Form
                                    buttonText='Update'
                                    url={updateURL}
                                    method='PATCH'
                                    inputs={{
                                        linkOne: {
                                            label: labelTwo,
                                            type: 'text',
                                            value: connection?.data?.linkTwo,

                                        },
                                    }}
                                />
                            </Card>
                            {fieldToShow > 2 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelThree,
                                                type: 'text',
                                                value: connection?.data?.linkThree,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 2 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelFour,
                                                type: 'text',
                                                value: connection?.data?.linkFour,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 2 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelFive,
                                                type: 'text',
                                                value: connection?.data?.linkFive,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 5 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelSix,
                                                type: 'text',
                                                value: connection?.data?.linkSix,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 5 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelSeven,
                                                type: 'text',
                                                value: connection?.data?.linkSeven,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 5 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelEight,
                                                type: 'text',
                                                value: connection?.data?.linkEight,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 5 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelNine,
                                                type: 'text',
                                                value: connection?.data?.linkNine,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 5 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: labelTen,
                                                type: 'text',
                                                value: connection?.data?.linkTen,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label11,
                                                type: 'text',
                                                value: connection?.data?.link11,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label12,
                                                type: 'text',
                                                value: connection?.data?.link12,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label13,
                                                type: 'text',
                                                value: connection?.data?.link13,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label14,
                                                type: 'text',
                                                value: connection?.data?.link14,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label15,
                                                type: 'text',
                                                value: connection?.data?.link15,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label16,
                                                type: 'text',
                                                value: connection?.data?.link16,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label17,
                                                type: 'text',
                                                value: connection?.data?.link17,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label18,
                                                type: 'text',
                                                value: connection?.data?.link18,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label19,
                                                type: 'text',
                                                value: connection?.data?.link19,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label20,
                                                type: 'text',
                                                value: connection?.data?.link20,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label21,
                                                type: 'text',
                                                value: connection?.data?.link21,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label22,
                                                type: 'text',
                                                value: connection?.data?.link22,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label23,
                                                type: 'text',
                                                value: connection?.data?.link23,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label24,
                                                type: 'text',
                                                value: connection?.data?.link24,

                                            },
                                        }}
                                    />
                                </Card>
                            )}
                            {fieldToShow > 10 && (
                                <Card>
                                    <Form
                                        buttonText='Update'
                                        url={updateURL}
                                        method='PATCH'
                                        inputs={{
                                            linkOne: {
                                                label: label25,
                                                type: 'text',
                                                value: connection?.data?.link25,

                                            },
                                        }}
                                    />
                                </Card>
                            )}


                        </Grid>
                    }
                </div>
            }

        </Animate >

    );
}
