/***
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: verify email address
*   Step 3: select plan
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, SocialSignin, Form, Link } from 'components/lib';

export function Signup(props) {

  const context = useContext(AuthContext);

  return (
    <Animate type='pop'>

      <section class="py-24 lg:pb-32 bg-gray-100 overflow-hidden">
        <div class="container px-4 mx-auto">
          <div class="max-w-lg text-center mx-auto">
            <h2 class="font-heading mb-12 text-6xl tracking-tighter">Create an account</h2>
            <div class="text-left mb-6 p-9 bg-white border border-gray-200 rounded-2xl">
              <Form
                inputs={{
                  name: {
                    label: 'First Name',
                    type: 'text',
                    required: true,
                    errorMessage: 'Please enter your first name'
                  },
                  email: {
                    label: 'Email',
                    type: 'email',
                    required: true,
                  },
                  password: {
                    label: 'Password',
                    type: 'password',
                    required: true,
                    complexPassword: true
                  },
                  confirm_password: {
                    type: 'hidden',
                    value: null,
                  },
                }}
                url='/api/account'
                method='POST'
                buttonText='Create Account'
                callback={context.signin} />
            </div>
            <p class="text-gray-600 font-medium tracking-tight">
              <span>Already have an account?</span>
              <a class="text-indigo-500 hover:text-indigo-600 transition duration-200" href="/signin"> Sign in</a>
            </p>
          </div>
        </div>
      </section>



    </Animate>
  );
}
