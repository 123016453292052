import { Fragment, useState } from 'react';
import { Loader, useAPI, Card, Animate, Message, Button, Grid } from 'components/lib';

export function Implement(props) {

    const [state, setState] = useState({});

    function createAPIKey(data) {
        window.location = '/account/apikeys/'
    }

    return (
        <Animate type='pop'>
            <Message
                closable
                title='Implementing Hreflang Manager'
                text='Once you have languages, locales and some connections added to our platform, you can implement our code on your website.'
                type='info'
            />
            <Card title="Step 1">
                <p class="mb-4">Create an API key. This allows us to authenticate you and retrieve your connections.</p>
                <Button
                    text='Create a Key'
                    action={createAPIKey}
                />
            </Card>
            <Card title="Step 2">
                <p class="mb-4">Use one of the following code snippets to implement with your website:</p>
                <p class="mb-4">Remember to replace the API key with the one you created earlier.</p>
                <Grid cols="2">
                    <Card title="JavaScript">
                        <pre>
                            <code>
                                {`const apiUrl = 'https://app.hreflangmanager.com/api/getConnections/';
const apiKey = 'key-8649f10bb9473375c8846f612e9710dbcdbe7b5ad15f549974af16475438a62a';
const currentPageUrl = encodeURIComponent(window.location.href);
const requestOptions = {
  method: 'GET',
  headers: {
    'Accept': 'application/json',
    'Authorization': 'Basic ' + apiKey
  }
};

fetch(apiUrl + currentPageUrl, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                const linkElements = responseData.data;
                console.log(responseData.data);
                linkElements.forEach(link => {
                    const linkElement = document.createElement('link');
                    linkElement.rel = 'alternate';
                    linkElement.href = link.href;
                    linkElement.hreflang = link.hreflang;
                    const headElement = document.querySelector('head');
                    headElement.appendChild(linkElement);
                });
            })
            .catch(error => console.log("No HREFlang Tags Found"));`}
                            </code>
                        </pre>
                    </Card>
                    <Card title="PHP">
                        <pre>
                            <code>
                                {`$curl = curl_init();

$apiUrl = 'https://app.hreflangmanager.com/api/getConnections/';
$apiKey = 'key-8649f10bb9473375c8846f612e9710dbcdbe7b5ad15f549974af16475438a62a';
$protocol = isset($_SERVER['HTTPS']) && $_SERVER['HTTPS'] === 'on' ? "https://" : "http://";
$currentPageUrl = urlencode($protocol . $_SERVER['HTTP_HOST'] . $_SERVER['REQUEST_URI']);

curl_setopt_array($curl, array(
    CURLOPT_URL => $apiUrl . $currentPageUrl,
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => 'GET',
    CURLOPT_HTTPHEADER => array(
        'Accept: application/json',
        'Authorization: Basic ' . $apiKey
    ),
));

$response = curl_exec($curl);

curl_close($curl);
if ($response !== false) {
    $responseData = json_decode($response, true);

    echo $responseData['data'];
}`}
                            </code>
                        </pre>
                    </Card>
                </Grid>
            </Card>
        </Animate>

    );
}
