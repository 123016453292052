/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';

export function Home(props) {

  return (
    <Fragment>

      <div class="pt-16 pb-28 bg-white overflow-hidden">
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl mx-auto text-center">
            <h1 class="font-heading mb-4 text-6xl md:text-7xl tracking-tighter">The Ultimate Hreflang Tag Manager</h1>
            <p class="mb-10 max-w-md mx-auto">Streamline Hreflang Tag Management for Enhanced International SEO</p>
            <a class="inline-block mb-6 px-5 py-4 text-white font-semibold tracking-tight bg-indigo-500 hover:bg-indigo-600 rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200" href="/signup">Try Our Free Plan</a>
            <p class="mb-20 text-gray-600 text-sm tracking-tight">No Credit Card Required / Cancel Anytime</p>
          </div>
          
        </div>
      </div>

    </Fragment>
  );
}
