import React from 'react';
import { Row, Content, Message } from 'components/lib';

export function NotFound(props) {

    return (
        <section class="relative py-20 sm:py-32 lg:py-64 overflow-hidden">
            <div class="relative container px-4 mx-auto">
                <div class="sm:flex items-start justify-center max-w-5xl mx-auto">
                    <div class="max-w-xs md:max-w-sm lg:max-w-2xl">
                        <h1 class="font-heading text-4xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-6">
                            <span>Oh no!</span>
                            <span class="font-serif italic block mt-4">Error 404</span>
                        </h1>
                        <p class="max-w-lg text-xl font-semibold text-gray-500 mb-6">Sorry, the page you are looking for doesn't exist or has been moved.</p>
                        <div class="w-auto hidden lg:block">
                        <div class="inline-block"><a class="inline-block px-5 py-3 text-white font-semibold text-center tracking-tight bg-indigo-500 hover:bg-indigo-600 rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200" href="/">Back Home</a></div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
