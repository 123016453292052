/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.tailwind.js';

export function Footer(props) {

  return (
    <Animate type='slideup'>

      <section class="py-9 bg-gray-700 overflow-hidden">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap items-center justify-between -m-4">
            <div class="w-auto p-4">
              <div class="flex flex-wrap items-center -m-7">
                <div class="w-auto p-7">
                  <a class="block" href="https://hreflangmanager.com/">
                    <img class="inline-block w-10 h-10" src="https://hreflangmanager.com/img/hreflang-manager-logo.png" alt="Hreflang Manager Logo"></img>
                  </a>
                </div>
                <div class="w-auto p-7">
                  <p class="text-sm text-white tracking-tight">© Copyright {new Date().getFullYear()} Hreflang Manager</p>
                </div>
              </div>
            </div>
            <div class="w-auto p-4">
              <div class="flex flex-wrap items-center -m-4">
                <div class="w-auto p-4">
                  <ul class="flex flex-wrap -m-4">
                    <li class="p-4"><a class="text-sm text-white hover:text-gray-300 tracking-tight transition duration-200" href="https://hreflangmanager.com/features/">Features</a></li>
                    <li class="p-4"><a class="text-sm text-white hover:text-gray-300 tracking-tight transition duration-200" href="https://hreflangmanager.com/pricing/">Pricing</a></li>
                    <li class="p-4"><a class="text-sm text-white hover:text-gray-300 tracking-tight transition duration-200" href="https://hreflangmanager.com/documentation/">Documentation</a></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </Animate>
  );
}