import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useParams } from "react-router";
import { Loader, useAPI, Table, Form, Grid, Card, Button, ViewContext, Animate } from 'components/lib';

export function Project(props) {
    const params = useParams();
    const projects = useAPI('/api/projects/' + params.id);
    const languages = useAPI('/api/languages');
    const context = useContext(ViewContext);
    const connections = useAPI('/api/connectionsProject/' + params.id);

    if (!languages || !projects)
        return false;

    function deleteConnection(data, callback) {
        context.modal.show({
            title: 'Delete Connection',
            form: {},
            buttonText: 'Delete Connection',
            text: `Are you sure you want to delete ${data.linkOne}?`,
            url: `/api/connections/${data.id}`,
            method: 'DELETE',
            destructive: true
        }, () => {
            context.notification.show(data.linkOne + ' was deleted', 'success', true);
            callback();
        });
    }

    function viewConnection(data) {
        window.location = '/connection/' + data.id;
    }

    function addConnection(data) {
        window.location = '/addConnection/';
    }

    function viewConnections(data) {
        window.location = '/connections/';
    }

    function setLanguages(data) {
        window.location = '/languages/';
    }

    function projectsMain(data) {
        window.location = '/projects/';
    }

    let title = projects?.data?.name+' Connections';

    return (
        <Fragment>
            <Animate>
                {projects.loading ? (
                    <Loader />
                ) : (
                    <Grid cols="1">
                        <div>
                            <Card title={title} last>
                                <Table
                                    search
                                    data={connections.data}
                                    show={['linkOne', 'langOne', 'countryOne']}
                                    loading={connections.loading}
                                    actions={{ delete: deleteConnection, custom: [{ icon: 'eye', action: viewConnection }] }}
                                >
                                </Table>
                            </Card>
                            <Card title='Quick Links'>
                                <Grid cols='4'>
                                    <Button
                                        text='Add a Connection'
                                        action={addConnection}
                                    />
                                    <Button
                                        text='View Connections'
                                        action={viewConnections}
                                    />
                                    <Button
                                        text='Set Languages'
                                        action={setLanguages}
                                    />
                                    <Button
                                        text='Projects'
                                        action={projectsMain}
                                    />
                                </Grid>
                            </Card>
                        </div>
                    </Grid>
                )}
            </Animate>
        </Fragment>

    );
}
